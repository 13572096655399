import React from "react";
import ReactDOM from "react-dom";

import { redux_service } from "services/redux.service";

import { Home } from "pages/home.js";

import "styles/post.css";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <redux_service.Provider store={redux_service.store}>
      <Home />
    </redux_service.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
