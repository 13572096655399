import React, { PureComponent } from "react";

import { Modal } from "components/modal";
import { Form } from "components/form";
import { Button } from "components/button";

import { challenge_service } from "services/challenge.service";
import { redux_service } from "services/redux.service";

/** @augments {PureComponent<{open: Boolean, loading: Boolean, success: Boolean, error: Boolean }>} */
class Challenge extends PureComponent {
  reset_timeout;

  prevent_more_characters = (e) => {
    const event = e.nativeEvent;

    if (!(event instanceof KeyboardEvent)) return;

    const { target } = event;
    if (!(target instanceof HTMLInputElement)) return;

    const { nextElementSibling, previousElementSibling } = target;

    if (event.key.length === 1) {
      if (!(nextElementSibling instanceof HTMLInputElement)) return;
      return nextElementSibling.focus();
    }

    if (event.key === "Backspace") {
      if (!(previousElementSibling instanceof HTMLInputElement)) return;
      return previousElementSibling.focus();
    }
  };

  componentDidUpdate() {
    clearTimeout(this.reset_timeout);

    if (this.props.error) this.reset_timeout = setTimeout(challenge_service.reset, 6000);

    if (this.props.success) this.reset_timeout = setTimeout(challenge_service.reset, 3000);
  }

  render() {
    const { open, loading, success, error } = this.props;

    return (
      <>
        {}
        <button
          onClick={success ? undefined : challenge_service.open}
          className="hover:text-primary transition duration-200"
        >
          {success ? "Welcome Agent" : "Are you a secret agent?"}
        </button>
        <Modal open={open} onClose={challenge_service.close}>
          <div
            className={`absolute inset-0 bg-opacity-75 transition duration-200 ${
              success ? "bg-primary" : error ? "bg-danger" : ""
            }`}
          ></div>
          <div className="absolute inset-0 center">
            <div
              className={`w-11/12 lg:w-6/12 bg-dark-blue px-4 py-12 md:p-12 flex flex-col justify-center ${
                error ? "animate-shake" : ""
              }`}
            >
              <p className={`font-medium text-16 uppercase ${success ? "text-primary" : error ? "text-danger" : ""}`}>
                {success ? "Access granted" : error ? "Access denied" : " "}
              </p>
              <p className="font-light text-32">What is your name agent?</p>
              <div className="h-4 w-4"></div>
              <Form className="flex flex-col" onSubmit={challenge_service.submit}>
                <div className="flex space-x-2 md:space-x-4">
                  {Array(8)
                    .fill(0)
                    .map((zero, i) => {
                      return (
                        <input
                          className="code-entry min-w-4 h-8 md:h-16 flex-1 bg-secondary rounded text-center md:text-24 text-primary uppercase font-medium"
                          name={`code-${i}`}
                          maxLength={1}
                          onKeyUp={this.prevent_more_characters}
                          autoComplete="off"
                        />
                      );
                    })}
                </div>
                <div className="w-8 h-8"></div>
                <div className="center">
                  <div className="w-full md:w-8/12">
                    <Button color="primary">Enter</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const ConnectedChallenge = redux_service.connect(Challenge, {
  open: "challenge/open",
  loading: "challenge/verify/loading",
  success: "challenge/verify/success",
  error: "challenge/verify/error",
});

export { ConnectedChallenge as Challenge };
