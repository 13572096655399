import React, { createRef, PureComponent } from "react";

import { redux_service } from "services/redux.service";

import { Modal } from "components/modal";
import { Button } from "components/button";

import play_icon from "assets/play.svg";
import pause_icon from "assets/pause.svg";
import teaser from "assets/teaser_montage_06_1kbr3.mp4";
import poster from "assets/BG.png";

/** @augments {PureComponent<{open: Boolean, play: Boolean }>} */
class Teaser extends PureComponent {
  video = createRef();
  progress = createRef();

  open = () => redux_service.action("teaser/open", true);

  close = () => redux_service.action("teaser/open", false);

  play = () => redux_service.action("teaser/play", true);

  pause = () => redux_service.action("teaser/play", false);

  seek = (e) => {
    const event = e.nativeEvent;
    if (!(this.video.current instanceof HTMLVideoElement)) return;
    const video = this.video.current;

    if (!(event instanceof MouseEvent)) return;
    const { target } = event;

    if (!(target instanceof HTMLDivElement)) return;

    const pos = (event.pageX - (video.offsetLeft + video.offsetParent.offsetLeft)) / video.offsetWidth;

    video.currentTime = pos * video.duration;
  };

  componentDidUpdate(props) {
    if (this.props.open === false) {
      if (!(this.video.current instanceof HTMLVideoElement)) return;

      this.video.current.pause();
    }

    if (this.props.play === true) {
      if (!(this.video.current instanceof HTMLVideoElement)) return;

      this.video.current.play();
    }

    if (this.props.play === false) {
      if (!(this.video.current instanceof HTMLVideoElement)) return;

      this.video.current.pause();
    }
  }

  componentDidMount() {
    if (!(this.video.current instanceof HTMLVideoElement)) return;
    const video = this.video.current;

    video.addEventListener("timeupdate", () => {
      if (!(this.progress.current instanceof HTMLDivElement)) return;

      const percentage = Math.floor((video.currentTime / video.duration) * 100);
      this.progress.current.style.width = `${percentage}%`;
    });

    video.addEventListener("pause", () => redux_service.action("teaser/play", false));
  }

  render() {
    const { open, play } = this.props;

    return (
      <>
        <Button color="white" onClick={this.open}>
          Watch full teaser
        </Button>
        <Modal open={open} onClose={this.close}>
          <div className="absolute inset-0 center">
            <div className={`w-10/12 flex flex-col`}>
              <video className="bg-black w-full h-full" src={teaser} poster={poster} ref={this.video}></video>
              <div className="controls flex h-12 items-center">
                <div className="w-8 h-8 center cursor-pointer" onClick={play ? this.pause : this.play}>
                  <img className="w-4 h-4 object-contain" src={play ? pause_icon : play_icon} />
                </div>
                <div className="bar bg-black h-1 flex-1 cursor-pointer" onClick={this.seek}>
                  <div className="progress bg-primary w-0 h-full transition-all duration-300" ref={this.progress}></div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const ConnectedTeaser = redux_service.connect(Teaser, {
  open: "teaser/open",
  play: "teaser/play",
});

export { ConnectedTeaser as Teaser };
