import React, { PureComponent } from "react";
import { Challenge } from "./challenge";
import { Icon } from "./icon";

/** @augments {PureComponent<{}>} */
class Footer extends PureComponent {
  render() {
    const now = new Date();

    return (
      <footer className="absolute bottom-0 pb-10 text-white w-full h-40 md:h-16">
        <div className="container flex flex-col md:flex-row items-center text-center justify-between">
          <p>
            &copy; {now.getFullYear()}{" "}
            <a href="https://mission-masters.com/" target="__blank" className="text-primary">
              Mission Masters
            </a>
            . All rights reserved
          </p>
          <div className="h-4 md:hidden"></div>
          <div className="flex items-center space-x-4">
            <Icon filename={"facebook"} url="https://www.facebook.com/missionmastersalbufeira/" />
            <Icon filename={"instagram"} url="https://www.instagram.com/missionmasters_albufeira" />
            <Icon
              filename={"tripadvisor"}
              url="https://www.tripadvisor.com/Attraction_Review-g189112-d17557821-Reviews-or25-Mission_Masters_Escape_Rooms_Albufeira-Albufeira_Faro_District_Algarve.html"
            />
          </div>
          <div className="h-4 w-4 md:hidden"></div>
          {/* <Challenge /> */}
        </div>
      </footer>
    );
  }
}

export { Footer };
