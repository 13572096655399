import React, { PureComponent } from "react";

import { redux_service } from "services/redux.service";

/** @augments {PureComponent<{challenge_success: Boolean}>} */
class FifthSectionLayer extends PureComponent {
  render() {
    const { challenge_success } = this.props;

    return (
      <div
        className={`absolute inset-0 -z-10 ${
          challenge_success ? "bg-primary opacity-25" : "opacity-75 bg-dark-blue md:hidden"
        }`}
      ></div>
    );
  }
}

const ConnectedFifthSectionLayer = redux_service.connect(FifthSectionLayer, {
  challenge_success: "challenge/verify/success",
});

export { ConnectedFifthSectionLayer as FifthSectionLayer };
