import React, { PureComponent } from "react";
import { redux_service } from "services/redux.service";

import map from "assets/symbolcypher-02.png";

class ChallengeMap extends PureComponent {
  render() {
    return (
      <img
        className="transform transition duration-200 opacity-5 hover:opacity-100"
        src={map}
        alt="Mission Masters Cypher Symbol Map"
      />
    );
  }
}

const ConnectedChallengeMap = redux_service.connect(ChallengeMap, {
  challenge_success: "challenge/verify/success",
});

export { ConnectedChallengeMap as ChallengeMap };
