import React, { PureComponent } from "react";

/** @augments {PureComponent<{type?: String, pattern?: String, name: String, children: String, on_input:function(React.FormEvent<HTMLInputElement>):void}>} */
class Input extends PureComponent {
  render() {
    const { type = "text", pattern, name, on_input, children } = this.props;

    return (
      <div className="bg-secondary w-full h-20">
        <input
          className="bg-transparent text-primary w-full h-full text-center placeholder-primary font-light text-20"
          name={name}
          type={type}
          placeholder={children}
          onInput={on_input ? on_input : undefined}
          autoComplete="off"
          pattern={pattern}
        />
      </div>
    );
  }
}

export { Input };
