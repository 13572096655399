import React, { PureComponent } from "react";

import { Section } from "components/section";
import { Button } from "components/button";
import { Screenplay } from "components/screenplay";
import { Sign } from "components/sign";
import { Footer } from "components/footer";
import { SubscribeForm } from "components/subscribe-form";
import { FifthSectionLayer } from "components/fifth-section-layer";
import { Teaser } from "components/teaser";
import { ChallengeMap } from "components/challenge-map";
import { Header } from "components/header";

import first_section_bg from "assets/teaser_8s_1kbr.mp4";
import fifth_section_bg from "assets/screens_experiences.png";

import { LINK_ANDROID } from "config";

class Home extends PureComponent {
  render() {
    return (
      <div className="relative">
        <Header />

        <main className="max-h-screen overflow-auto scroll-smooth">
          <div className="fixed inset-0 w-screen h-screen -z-10 bg-gradient-to-tr from-dark-blue to-deep-teal"></div>
          <div id="mission-masters" className="header-referencial"></div>
          <Section video={first_section_bg}>
            <div className="absolute inset-0 bg-dark-blue opacity-50"></div>
            <div className="absolute inset-0 center">
              <div className="flex pb-32">
                <p className="text-white text-32 md:text-97 font-hairline">Welcome Agent</p>
              </div>
            </div>
            <div className="absolute inset-0 bg-primary opacity-25" style={{ mixBlendMode: "multiply" }}></div>

            <div className="absolute bottom-0 w-full mb-20 md:mb-40 center">
              <div className="flex flex-col lg:flex-row pt-32 text-18 font-medium text-white uppercase">
                {/* <Button color="primary" href={LINK_IOS}>
                  Download for iOS
                </Button>
                <div className="min-w-8 h-8 w-16 flex-auto"></div> */}
                <Teaser />
                <div className="min-w-8 h-8 w-16 flex-auto"></div>
                <Button color="primary" href={LINK_ANDROID}>
                  Download for Android
                </Button>
              </div>
            </div>
          </Section>

          <Section>
            <div className="flex flex-col md:flex-row container items-center h-full w-full">
              <Screenplay
                filename={"screenplayer_01"}
                alt={"Mission Masters Gameplay Room"}
                enter="animate-fade-in-to-r"
              />
              <div className="w-full center text-center text-32 font-light relative">
                <div className="w-6/12">
                  <p>
                    One player escapes, <br /> the other tell him how.
                  </p>
                </div>
                <div className="w-full absolute -z-10">
                  <Sign filename={"symbol01"} />
                </div>
              </div>

              <Screenplay
                filename={"screenhacker_01"}
                alt={"Mission Masters Gameplay Hacker"}
                enter="animate-fade-in-to-l"
              />
            </div>
          </Section>

          <Section>
            <div className="flex flex-col md:flex-row container items-center h-full w-full">
              <Screenplay
                filename={"screenplayer_02"}
                alt={"Mission Masters Gameplay Room"}
                enter="animate-fade-in-to-r"
              />
              <div className="w-full center text-center text-32 font-light relative">
                <div className="w-6/12">
                  <p>
                    Explore the story-filled room <br /> in augmented reality
                  </p>
                </div>
                <div className="w-full absolute -z-10">
                  <Sign filename={"symbol02"} />
                </div>
              </div>

              <Screenplay
                filename={"screenhacker_02"}
                alt={"Mission Masters Gameplay Hacker"}
                enter="animate-fade-in-to-l"
              />
            </div>
          </Section>

          <Section>
            <div className="flex flex-col md:flex-row container items-center h-full w-full">
              <Screenplay
                filename={"screenplayer_03"}
                alt={"Mission Masters Gameplay Room"}
                enter="animate-fade-in-to-r"
              />
              <div className="w-full center text-center text-32 font-light relative">
                <div className="w-6/12">
                  <p>With all the puzzles you love </p>
                </div>
                <div className="w-full absolute -z-10 flex justify-between">
                  <Sign filename={"symbol03"} />
                  <Sign filename={"symbol04"} />
                </div>
              </div>

              <Screenplay
                filename={"screenhacker_03"}
                alt={"Mission Masters Gameplay Hacker"}
                enter="animate-fade-in-to-l"
              />
            </div>
          </Section>

          <Section>
            <div id="game" className="absolute inset-0 bg-dark-blue -z-10 hidden md:block"></div>
            <img
              className="absolute inset-0 h-full object-contain md:-ml-64 -z-10"
              src={fifth_section_bg}
              alt="Mission Masters"
            />
            <FifthSectionLayer />

            <div className="container h-full flex flex-col md:flex-row items-center">
              <div className="h-24"></div>
              <div className="w-10/12 md:w-6/12 relative group">
                {/* <ChallengeMap />

                <div className="absolute inset-0 -z-10 transition duration-200 group-hover:bg-radial-shadow"></div> */}
              </div>
              <div className="w-10/12 md:w-6/12 flex flex-col font-light items-center text-center">
                <p className="text-32 text-primary">Play Now</p>
                <br />
                <p className="text-16 text-white opacity-50">Dare to escape?</p>
                <br />
                <br />
                {/* <Button color="primary" compact={true}>
                  Download for iOS
                </Button>
                <br /> */}
                <Button color="primary" compact={true} href={LINK_ANDROID}>
                  Download for Android
                </Button>
              </div>
            </div>

            <div className="h-40 mt-10 md:h-16"></div>

            <Footer />
          </Section>
        </main>
      </div>
    );
  }
}

export { Home };
