function intersection_observer($elements, options) {
  if (!($elements instanceof Array)) $elements = [$elements];

  return $elements.map(function ($el) {
    var observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.intersectionRatio > 0) {
          entry.target.dispatchEvent(new Event("intersection-visible"));
        } else {
          entry.target.dispatchEvent(new Event("intersection-invisible"));
        }
      });
    }, options);

    observer.observe($el);

    return observer;
  });
}

export { intersection_observer };
