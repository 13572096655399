import { api_service } from "./api.service";
import { redux_service } from "./redux.service";

class ChallengeService {
  open = (e) => {
    redux_service.action("challenge/open", true);
  };

  close = (e) => {
    redux_service.action("challenge/open", false);
  };

  reset() {
    redux_service.action("challenge/open", undefined);
    redux_service.action("challenge/verify/error", undefined);
    redux_service.action("challenge/verify/loading", undefined);
  }

  submit = async (e) => {
    e.preventDefault();
    const event = e.nativeEvent;

    if (!(event instanceof Event)) return;

    const form = event.target;
    if (!(form instanceof HTMLFormElement)) return;

    redux_service.action("challenge/verify/loading", true);

    const $entries = Array.from(form.querySelectorAll(".code-entry"));

    let code = $entries.reduce((acc, cur) => {
      if (!(cur instanceof HTMLInputElement)) return "";

      return acc + cur.value;
    }, "");

    code = code.toUpperCase();

    redux_service.action("challenge/verify/code", code);

    const { json, status } = await api_service.get_challenge({ code });

    if (status === 200 && json.valid) {
      redux_service.action("challenge/verify/success", true);
    } else redux_service.action("challenge/verify/error", true);

    redux_service.action("challenge/verify/loading", false);
  };
}

const challenge_service = new ChallengeService();

export { challenge_service };
