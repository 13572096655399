import React, { PureComponent } from "react";

/** @augments {PureComponent<{href?: String, onClick?: Function, color:String, disabled?: Boolean, compact?: Boolean}>} */
class Button extends PureComponent {
  onClick = (e) => {
    if (!this.props.onClick) return;

    return this.props.onClick(e);
  };

  render() {
    let { children, color = "white", onClick, href, disabled = false, compact = false } = this.props;

    if (disabled) color = "white";

    const props = {
      className: `bg-transparent text-${color} uppercase min-w-64 lg:min-w-80 ${
        compact ? "h-12" : "h-12 lg:h-20"
      } rounded group relative hover:overflow-hidden ${disabled ? "pointer-events-none opacity-50" : ""}`,
    };

    const child = (
      <>
        <div
          className={`absolute inset-0 border-2 border-${color} border-opacity-25 center text-current rounded ${
            disabled ? "" : "animate-emerge"
          } group-hover:bg-white group-hover:animate-shift-myself`}
        ></div>
        <div className="absolute inset-0 border-2 border-current center text-current rounded group-hover:text-deep-teal">
          {children}
        </div>
      </>
    );

    if (href)
      return (
        <a href={href} {...props}>
          {child}
        </a>
      );
    else
      return (
        <button type={onClick ? "button" : "submit"} onClick={this.onClick} {...props}>
          {child}
        </button>
      );
  }
}

export { Button };
