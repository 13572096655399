import {} from "react-dom";
import { api_service } from "./api.service";
import { redux_service } from "./redux.service";

class EmailsService {
  create = async (e) => {
    e.preventDefault();
    const event = e.nativeEvent;

    if (!(event instanceof Event)) return;

    const form = event.target;
    if (!(form instanceof HTMLFormElement)) return;

    redux_service.action("email/create/loading", true);

    const $email = form.elements.namedItem("email");

    if (!($email instanceof HTMLInputElement)) return;

    const { "challenge/verify/code": code } = redux_service.state();

    const email = $email.value;

    const { status } = await api_service.post_emails({ email, code });

    if (status === 200) redux_service.action("email/create/success", true);
    else if (status === 409) redux_service.action("email/create/success", true);
    else redux_service.action("email/create/error", true);

    redux_service.action("email/create/loading", false);
  };

  reset() {
    redux_service.action("email/create/success", undefined);
    redux_service.action("email/create/error", undefined);
    redux_service.action("email/create/loading", undefined);
  }
}

const emails_service = new EmailsService();

export { emails_service };
