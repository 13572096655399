import React, { PureComponent } from "react";

/** @augments {PureComponent<{open: boolean, onClose: Function}>} */
class Modal extends PureComponent {
  onClose = (e) => {
    return this.props.onClose(e);
  };

  componentDidUpdate(props) {
    const { open } = this.props;

    const $main = document.querySelector("main");

    if (open) $main.classList.add("overflow-hidden");
    if (!open) $main.classList.remove("overflow-hidden");
  }

  render() {
    const { children, open } = this.props;

    return (
      <div className={`fixed inset-0 z-20 transition-all duration-200 overflow-hidden ${open ? "h-full" : "h-0"}`}>
        <div className="absolute inset-0 bg-black opacity-75 -z-10"></div>

        {children}

        <button
          className="absolute top-0 right-0 mt-2 md:mt-8 mr-8 md:mr-16 text-97 font-hairline"
          onClick={this.onClose}
        >
          &times;
        </button>
      </div>
    );
  }
}

export { Modal };
