class ApiService {
  server_url = "/api";

  async request({ method = "GET", url, body = null, query = {} }) {
    const final_url = new URL(`${this.server_url}${url}`, window.location.origin);

    for (const key in query) {
      if (!query[key]) continue;

      final_url.searchParams.append(key, query[key]);
    }

    const raw = await fetch(final_url.toString(), {
      method,
      body: body ? JSON.stringify(body) : null,
      headers: { "Content-Type": "application/json" },
    });

    const status = raw.status;
    const json = await raw.json();

    return { json, status };
  }

  async post_emails({ email, code }) {
    return this.request({ url: `/emails`, method: "POST", body: { email, code } });
  }

  async get_challenge({ code }) {
    return this.request({ url: `/challenge`, query: { code } });
  }
}

const api_service = new ApiService();

export { api_service };
