import React, { createRef, PureComponent } from "react";

import { Button } from "components/button";

import { intersection_observer } from "utils/intersection-observer";

import logo from "assets/missionmasters_logo.png";

/** @augments {PureComponent<{}>} */
class Header extends PureComponent {
  header = createRef();

  on_top = () => {
    if (!(this.header.current instanceof HTMLElement)) return;

    this.header.current.classList.remove("bg-opacity-100");
  };

  outside_top = () => {
    if (!(this.header.current instanceof HTMLElement)) return;

    this.header.current.classList.add("bg-opacity-100");
  };

  componentDidMount() {
    window.addEventListener("load", () => {
      const referencial = document.querySelector(".header-referencial");
      if (!(referencial instanceof HTMLDivElement)) return;

      intersection_observer(referencial);

      referencial.addEventListener("intersection-visible", this.on_top);
      referencial.addEventListener("intersection-invisible", this.outside_top);
    });
  }

  render() {
    return (
      <header
        className="absolute top-0 z-20 w-full transition duration-200 bg-opacity-0 bg-dark-blue h-24"
        ref={this.header}
      >
        <nav className="container flex justify-center md:justify-start items-center">
          <a href="#mission-masters">
            <h1>
              <img src={logo} alt="Mission Masters Albufeira Logo" />
            </h1>
          </a>
        </nav>
      </header>
    );
  }
}

export { Header };
