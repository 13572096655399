import React, { PureComponent } from "react";

import { redux_service } from "services/redux.service";

import symbol01 from "assets/symbol01.svg";
import symbol02 from "assets/symbol02.svg";
import symbol03 from "assets/symbol03.svg";
import symbol04 from "assets/symbol04.svg";

/** @augments {PureComponent<{filename: String, challenge_success: Boolean}>} */
class Sign extends PureComponent {
  images = {
    symbol01,
    symbol02,
    symbol03,
    symbol04,
  };

  render() {
    const { filename, challenge_success } = this.props;

    if (challenge_success) return null;

    return (
      <div className="w-32 h-32">
        <img className="w-full max-h-full" src={this.images[filename]} />
      </div>
    );
  }
}

const ConnectedSign = redux_service.connect(Sign, {
  challenge_success: "challenge/verify/success",
});

export { ConnectedSign as Sign };
