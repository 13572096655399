import React, { createRef, PureComponent } from "react";

import screenplayer_01 from "assets/screenplayer_01.png";
import screenhacker_01 from "assets/screenhacker_01.png";
import screenplayer_02 from "assets/screenplayer_02.png";
import screenhacker_02 from "assets/screenhacker_02.png";
import screenplayer_03 from "assets/screenplayer_03.png";
import screenhacker_03 from "assets/screenhacker_03.png";
import { intersection_observer } from "utils/intersection-observer";

/** @augments {PureComponent<{enter: String, filename: String, alt: String}>} */
class Screenplay extends PureComponent {
  image = createRef();

  images = {
    screenplayer_01,
    screenhacker_01,
    screenplayer_02,
    screenhacker_02,
    screenplayer_03,
    screenhacker_03,
  };

  on_visible = () => {
    if (!(this.image.current instanceof HTMLImageElement)) return;

    this.image.current.src = this.image.current.dataset.src;
    this.image.current.classList.add(this.props.enter);
  };

  on_invisible = () => {
    if (!(this.image.current instanceof HTMLImageElement)) return;

    this.image.current.classList.remove(this.props.enter);
  };

  componentDidMount() {
    if (!(this.image.current instanceof HTMLImageElement)) return;

    intersection_observer(this.image.current);

    this.image.current.addEventListener("intersection-visible", this.on_visible);
    this.image.current.addEventListener("intersection-invisible", this.on_invisible);
  }

  render() {
    const { filename, alt } = this.props;

    return (
      <div className="w-full center overflow-hidden">
        <img className="max-w-xl" data-src={this.images[filename]} alt={alt} ref={this.image} />
      </div>
    );
  }
}

export { Screenplay };
