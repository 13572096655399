import React, { PureComponent } from "react";

/** @augments {PureComponent<{onSubmit: Function, className?:string}>} */
class Form extends PureComponent {
  onSubmit = (e) => {
    e.preventDefault();

    return this.props.onSubmit(e);
  };

  render() {
    const { children, className } = this.props;

    return (
      <form className={className} onSubmit={this.onSubmit}>
        {children}
      </form>
    );
  }
}

export { Form };
