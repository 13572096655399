import React, { Component } from "react";
import { redux_service } from "services/redux.service";

/**@augments {Component<{img?: String, video?: String,teaser_open: Boolean}>} */
class Section extends Component {
  id = `section_${Date.now()}`;

  shouldComponentUpdate() {
    const video = document.querySelector(`#${this.id} video`);

    if (!(video instanceof HTMLVideoElement)) return;

    if (this.props.teaser_open) video.play();
    else video.pause();

    return false;
  }

  render() {
    const { children, img, video } = this.props;

    return (
      <article id={this.id} className="min-h-screen md:h-px w-full relative text-white overflow-hidden pt-24">
        {video ? (
          <video
            className="absolute inset-0 w-full h-full object-cover -z-10"
            src={video}
            autoPlay
            muted
            loop
            playsInline
          ></video>
        ) : img ? (
          <img className="absolute inset-0 w-full h-full object-cover -z-10" src={img} alt="Mission Masters" />
        ) : null}
        {children}
      </article>
    );
  }
}

const ConnectedSection = redux_service.connect(Section, {
  teaser_open: "teaser/open",
});

export { ConnectedSection as Section };
