import React, { PureComponent } from "react";

import { emails_service } from "services/emails.service";
import { redux_service } from "services/redux.service";

import { Button } from "components/button";
import { Form } from "components/form";
import { Input } from "components/input";

/** @augments {PureComponent<{}>} */
class Error extends PureComponent {
  render() {
    return (
      <>
        <p className="text-16 text-danger text-center">Sorry, but we couldn't register you.</p>
        <div className="w-4 h-4"></div>

        <p className="text-16 text-danger text-center">
          Please contact our support at{" "}
          <a href="mailto:info@mission-masters.com" className="underline">
            info@mission-masters.com
          </a>
        </p>
        <div className="w-4 h-4"></div>

        <p>
          <Button color="white" onClick={emails_service.reset}>
            OK
          </Button>
        </p>
      </>
    );
  }
}

/** @augments {PureComponent<{}>} */
class Success extends PureComponent {
  render() {
    return (
      <>
        <p className="text-16 text-primary text-center">Thank you for registering.</p>
        <div className="w-4 h-4"></div>
        <p className="text-16 text-primary text-center">
          You will receive an email when Mission Masters AR is released
        </p>
        <div className="w-4 h-4"></div>

        <p>
          <Button color="white" onClick={emails_service.reset}>
            OK
          </Button>
        </p>
      </>
    );
  }
}

/** @augments {PureComponent<{loading: Boolean, with_discount: Boolean, email?: String}>} */
class Initial extends PureComponent {
  render() {
    const { with_discount, email } = this.props;

    return (
      <>
        <p className="text-16 text-white opacity-50">Receive an email when Mission Masters AR is released</p>
        <br />
        {with_discount ? (
          <>
            <p className="text-16 text-primary font-medium">With 50% Special Agent Discount!</p>
            <br />
          </>
        ) : null}
        <div className="self-stretch">
          <Form onSubmit={emails_service.create}>
            <div className="flex flex-col">
              <Input
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                name="email"
                on_input={(e) => {
                  if (!(e.target instanceof HTMLInputElement)) return;
                  const target = e.target;

                  return target.checkValidity()
                    ? redux_service.action("email", target.value)
                    : redux_service.action("email", "");
                }}
              >
                Insert Email
              </Input>
              <br />
              <Button color="primary" disabled={email ? false : true} compact={true}>
                Subscribe to mailing list
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

/** @augments {PureComponent<{loading: Boolean, success: Boolean, error: Boolean, challenge_success: Boolean, email?: String}>} */
class SubscribeForm extends PureComponent {
  render() {
    const { loading, success, error, challenge_success, email } = this.props;

    return success ? (
      <Success />
    ) : error ? (
      <Error />
    ) : (
      <Initial loading={loading} with_discount={challenge_success} email={email} />
    );
  }
}

const ConnectedSubscribeForm = redux_service.connect(SubscribeForm, {
  challenge_success: "challenge/verify/success",
  email: "email",
  loading: "email/create/loading",
  success: "email/create/success",
  error: "email/create/error",
});

export { ConnectedSubscribeForm as SubscribeForm };
