import React, { PureComponent } from "react";

import facebook from "assets/facebook.svg";
import instagram from "assets/instagram.svg";
import tripadvisor from "assets/tripadvisor.svg";

/** @augments {PureComponent<{filename: String, url: String}>} */
class Icon extends PureComponent {
  images = {
    facebook,
    instagram,
    tripadvisor,
  };

  render() {
    const { filename, url } = this.props;

    return (
      <a href={url} target="__blank">
        <img src={this.images[filename]} />
      </a>
    );
  }
}

export { Icon };
