import { createStore } from "redux";
import { connect, Provider } from "react-redux";
import lo_get from "lodash/get";
import lo_mapValues from "lodash/mapValues";

class ReduxService {
  Provider = Provider;
  store = null;
  listeners = new Map();

  constructor() {
    this.store = createStore(
      this.reducer.bind(this),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    window.redux = this;
  }

  reducer(state = {}, { type, payload }) {
    return { ...state, [type]: payload };
  }

  action(type, payload) {
    this.store.dispatch({ type, payload });

    if (this.listeners.has(type))
      this.listeners.get(type).forEach((cb) => cb(this.store.getState(), { type, payload }));
  }

  connect(Component, props) {
    return connect((state) => lo_mapValues(props, (value) => lo_get(state, value)))(Component);
  }

  state() {
    const state = this.store.getState();

    return state;
  }

  on(type, callback) {
    if (typeof callback !== "function") return;

    const current = this.listeners.get(type) || [];

    if (!Array.isArray(current)) return;

    this.listeners.set(type, current.concat(callback));

    const state = this.state();

    if (type in state) {
      callback(state, { type, payload: state[type] });
    }
  }

  off(type, callback) {
    if (typeof callback !== "function") return;

    const current = this.listeners.get(type) || [];

    if (!Array.isArray(current)) return;

    const listener_index = current.findIndex((listener) => listener === callback);

    if (listener_index === -1) return;

    current.splice(listener_index, 1);

    this.listeners.set(type, [...current]);
  }
}

const redux_service = new ReduxService();

export { redux_service };
